import { Experiment } from '@amplitude/experiment-js-client';

const experiment = Experiment.initializeWithAmplitudeAnalytics('client-GgZa4BIbZNKvh8HZLuQySJjeyWpqejCV');

export const useGetAmplitudeExperimentInfo = () => {
  const getAmplitudeUser = async (user: any) => {
    const response = await experiment.fetch(user);
    return response;
  };

  const getUpgradeButtonVariant = async () => {
    const response = await experiment.variant('upgrade-button', { value: 'new' });
    // console.log('🚀 ~ getUpgradeButtonVariant ~ response:', response);
    return response.value;
  };

  return { getAmplitudeUser, getUpgradeButtonVariant };
};
